import React, { useEffect } from "react";
import Services from "./Services";
import Slider from "./Slider";
import Links from "./Links";
import { SEOText, SEOHeader, SeoContainer } from "./index-styled";
import { useTranslation } from "react-i18next";
import SEO from "./seo";
import { useExtractHL } from "@/hooks/useExtractHL";
import { HOME_NS as ns } from "@/i18n/ns";
import { FullScreenSpin as Spin } from "_c/preloader";
import { Redirect } from "react-router-dom";
import { useParsedQuery } from "@/hooks/useParsedQuery";
import { useStore } from "react-redux";
import { INITIAL_SCREENS_TO_ROUTE_SELECTOR_MAP } from "@/config";

const HomePage = (props) => {
  const { t, ready } = useTranslation(["common", ns], {
    useSuspense: false,
  });
  const store = useStore();
  const parsedQuery = useParsedQuery();
  const shouldRedirectToProfile = parsedQuery["profile-redirect"];
  const { affiliate_code } = parsedQuery;
  const hl = useExtractHL(parsedQuery);
  useEffect(() => {
    // ssr авторизует по сессии на всех роутах кроме домашней страницы(которую кеширует сервис воркер)
    // кроме того мы не должны посылать запрос на авторизацию в случае если в query висит affiliate_code,
    // в этом случае просто посылаем запрос на уничтожение сессии
    if (affiliate_code) {
      //logoutRequest();
      //if (affiliate_type === "master" || affiliate_type === "client") {
      //  props.addAffiliateData({ affiliate_code, affiliate_type });
      //  props.openAuthModal();
      //}
    } else if (!props.isAuth) {
      props.sessionLogin();
    }
  }, []);
  if (!ready) return <Spin size="xl" />;
  if (props.isAuth) {
    if (shouldRedirectToProfile) {
      return <Redirect to={props.profileLink} />;
    } else if (props.initialScreen) {
      const selector =
        INITIAL_SCREENS_TO_ROUTE_SELECTOR_MAP[props.initialScreen];
      if (selector) {
        const route = selector(store.getState())?.route;
        if (route) {
          return <Redirect to={route} />;
        }
      }
    }
  }
  return (
    <>
      <SEO ns={ns} t={t} />
      <Services ns={ns} t={t} />
      <Slider t={t} />
      <SeoContainer>
        <SEOHeader>{t("common:main_head")}</SEOHeader>
        <SEOText>{t(`${ns}:bottom_text`)}</SEOText>
      </SeoContainer>
    </>
  );
};

export default HomePage;
