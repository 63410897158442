import styled from "styled-components";

export const Wrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  margin: auto;
  max-width: 1366px;
`;
export const Content = styled.section`
  width: 100%;
  position: relative;
  z-index: 20;
  background-color: #fffce9;
  &:after {
    content: "";
    background-image: url(/img/contentBg2.png);
    background-repeat: no-repeat;
    background-position: top center;
    height: 1305px;
    position: absolute;
    top: -22vw;
    left: 0px;
    right: 0;
    z-index: 20;
    pointer-events: none;
    background-size: 100%;
  }
`;
export const Img2 = styled.div`
  width: 50%;
  height: 682px;
  background-image: url("images/contentBg1.png");
  background-position: 120px 0;
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  z-index: 32;
  top: -85px;
  right: 0;
  @media (max-width: 991px) {
    top: 0;
    width: 77%;
    background-size: 150%;
  }
`;
export const Popular = styled.section`
  position: relative;
  color: var(--font-color-emp);
  padding-top: 8px;
  max-width: 1366px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  .pop-wraper {
    text-align: center;
    position: relative;
    z-index: 35;
  }

  & > img {
    position: absolute;
    z-index: 32;
    left: -2%;
    top: 0px;
  }
  @media (max-width: 991px) {
    min-height: auto;
    .pop-wraper {
      margin: 20px 0;
    }
    & > img {
      left: -100px;
      bottom: 20px;
      top: auto;
      width: 300px;
    }
  }
`;
export const GoTo = styled.span.attrs({
  role: "presentation",
})`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  padding-top: 2px;
  border-radius: 50%;
  background-color: white;
  left: calc(50% - 15px);
  top: -17px;
  z-index: 33;
  box-shadow: 0px 0px 21px 0px rgba(0, 0, 0, 0.4);
  @media (max-width: 767px) {
    top: -15px;
  }
`;
export const Row = styled.div.attrs({
  className: "row mx-0",
})`
  @media (max-width: 767px) {
    justify-content: center;
  }
`;
export const Col = styled.div.attrs({
  className: "col-md col-12 pop-wraper",
})`
  > span {
    max-width: 240px;
  }
  @media (max-width: 767px) {
    &&& {
      flex-grow: 1;
      flex-basis: 0;
      width: auto;
    }
  }
`;

export const IconWrap = styled.div`
  position: relative;
  margin: 0 auto;
  width: 172px;
  height: 172px;
  border-radius: 50%;
  background-image: linear-gradient(to bottom, #ffffff, #fdfdfb);
  margin-bottom: 15px;
  &::after {
    content: "";
    position: absolute;
    background-color: white;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 130px;
    border-radius: 50%;
    height: 130px;
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  }
  img {
    z-index: 3;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    height: 75px;
  }
`;
