import React from "react";
import { Popular, Row, Col, IconWrap, Wrapper } from "./styled";
import range from "lodash/range";

const features = range(1, 5);
const Services = ({ t, ns }) => {
  return (
    <Popular>
      <Wrapper>
        <Row>
          {features.map((key) => (
            <Col key={key}>
              <IconWrap>
                <img
                  src={`/svg/services-${key}.svg`}
                  alt="top"
                  role="presentation"
                />
              </IconWrap>
              <span>{t(`${ns}:advantages.${key}`)}</span>
            </Col>
          ))}
        </Row>
      </Wrapper>
    </Popular>
  );
};

export default Services;
