import React from "react";
import { Helmet } from "_c/Helmet";
import { SITE_URL } from "@/misc/constants";
import qs from "query-string";

export default ({ t, ns }) => {
  const canonical = qs.stringifyUrl({
    url: `${SITE_URL}/`,
  });
  return (
    <Helmet
      link={[{ rel: "canonical", href: canonical }]}
      meta={[
        {
          name: "description",
          content: t(`${ns}:seo.meta_description`),
        },
      ]}
      title={t(`${ns}:seo.meta_title`)}
    />
  );
};
