import { Container as Cont } from "reactstrap";
import styled from "styled-components";

export const Container = styled(Cont)`
  position: relative;
  z-index: 36;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  max-width: 100%;
  margin-top: 80px;
  padding-bottom: 65px !important;
  @media (max-width: ${({ theme }) => theme.mobileBp}) {
    padding-bottom: 0 !important;
    scroll-snap-type: x mandatory;
    flex-wrap: nowrap;
    align-items: center;
    overflow-x: scroll;
  }
`;
