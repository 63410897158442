import React, { memo, useMemo } from "react";
import Slide from "./slide";
import { Container } from "./styled";
import { MASTER_SEARCH_RADIUS } from "@/misc/constants";
import enPlaces from "@/misc/searchPlaces/en.json";
import ruPlaces from "@/misc/searchPlaces/ru.json";
import { useLocale } from "@/hooks/useLocale";

const BASE_URL = "/images/places";
const SEARCH_URL = "/search";
const CitiesSlider = (props) => {
  const locale = useLocale();
  const slides = useMemo(() => {
    let rawSlides;
    if (locale === "ru") {
      rawSlides = ruPlaces;
    } else if (locale === "en") {
      rawSlides = enPlaces;
    } else {
      rawSlides = enPlaces;
    }
    return rawSlides.map(({ title, image, coords, slug }) => ({
      title,
      image: `${BASE_URL}/${image}`,
      url: slug
        ? `${SEARCH_URL}/${slug}`
        : `${SEARCH_URL}?lat=${coords.lat}&lng=${coords.lng}&radius=${MASTER_SEARCH_RADIUS}&address_label=${title}`,
    }));
  }, [locale]);
  return (
    <Container>
      {slides.map((city, i) => (
        <Slide t={props.t} key={i} city={city} />
      ))}
    </Container>
  );
};
export default memo(CitiesSlider);
