import s from "styled-components";
import { Container } from "reactstrap";

export const SeoContainer = s(Container)`
  && {
    @media(max-width: 575px) {
      padding: 0 10px !important;
    }
  }
`;
export const SEOHeader = s.h1`
  margin: 0;
  margin-top: 70px;
  margin-bottom: 20px;
  font-family: ${({ theme }) => theme.font.accent};
  text-align: center;
  color: var(--font-color-emp);
  font-size: 39px;
  font-weight: 400;
  max-width: 530px;
  margin-left: auto;
  margin-right: auto;
`;
export const SEOText = s.p`
  margin-top: 0;
  line-height: 1.45;
  color: var(--font-color-main);
`;
