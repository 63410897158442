import styled from 'styled-components'

const BASE = '/icons/';

const i = src => styled.img.attrs({ src: BASE + src, role: 'presentation' })``;

// ^-^ PURPLE ^-^
export const GearIconPurple = i('gear.svg');
export const ClockIconPurple = i('clock.svg');
export const ScriptIconPurple = i('script.svg');
export const StarIconPurple = i('star.svg');
export const ShareIconPurple = i('share.svg');
export const TagIconPurple = i('tar.svg');
export const PinIconPurple = i('pin.svg');
export const CheckmarkIconPurple = i('checkmark_purple.svg');
export const HeartIconPurple = styled(i('heart_purple.svg'))`
  padding-top: 1px;
`;
export const HeartIconFillPurple = i('heart_filled_purple.svg');
export const CommentIconPurple = i('comment_purple.svg');
export const DenyIconPurple = i('deny_purple.svg');
export const ArrowBottomIconPurple = i('arrow_bottom.svg');

// ^-^ WHITE ^-^
export const GearIconWhite = i('gear_white.svg');
export const CheckmarkIconWhite = i('checkmark.svg');
export const BellIconWhite = i('bell.svg');
export const BuildingIconWhite = i('building.svg');
export const CarIconWhite = i('car.svg');
export const EnvelopeIconWhite = i('envelope.svg');
export const HomeIconWhite = i('home.svg');
export const ShareIconWhite = i('share_white.svg');
export const DenyIconWhite = i('deny.svg');
export const PlusIconWhite = i('plus.svg');
export const AngleBottomIconWhite = i('angle_bottom.svg');
export const AngleTopIconWhite = i('angle_top.svg');
export const AngleLeftIconWhite = i('angle_left.svg');
export const AngleRightIconWhite = i('angle_right.svg');
export const BagPlusIconWhite = i('bag_plus.svg');
export const CameraPlusIconWhite = i('camera.svg');
export const ArrowLeftIconWhite = i('arrow_left.svg');
export const PinIconWhite = i('pin_white.svg');

// ^-^ SOCIALS ^-^
export const FacebookIcon = i('facebook.svg');
export const PinterestIcon = i('pinterest.svg');
export const InstagramIcon = i('instagram.svg');
export const LinkedinIcon = i('linkedin.svg');
export const TwitterIcon = i('twitter.svg');
export const YoutubeIcon = i('youtube.svg');

// ^-^ PINK ^-^
export const CheckmarkIconPink = i('checkmark_pink.svg');
export const PinIconPink = i('pin_pink.svg');
export const SearchIconPink = i('search_pink.svg');
export const BasketIconPink = i('basket_pink.svg');
export const AngleBottomIconPink = i('angle_bottom_pink.svg');

// ^-^ GRAY ^-^
export const AngleLeftIconLight = i('angle_left_light.svg');
export const AngleRightIconLight = i('angle_right_light.svg');

// ^-^ GMAP MAP ^-^
export const GoogleMapsPinIcon = i('google_maps_pin.svg');
