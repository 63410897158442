import s from "styled-components";
import { Link as RouterLink } from "react-router-dom";
import { AngleRightIconWhite } from "_c/icon";

export const AngleRight = s(AngleRightIconWhite)`
  width: 5px;
  padding-top: 1px;
  margin-left: 4px;
`;
export const Meta = s.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: absolute;
  bottom: 22px;
  left: 0;
  padding-inline-start: 20px;
  width: 100%;
  color: white;
`;
export const Location = s.span`
  margin-bottom: 5px;
  font-size: 22px;
  font-weight: 600;
  @media (max-width: ${({ theme }) => theme.mobileBp}) {
    font-size: 18px;
  }
`;
export const Link = s(RouterLink)`
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  transition: opacity .35s ease;
  :hover {
    opacity: .7;
  }
`;

const gapDesktop = 8;
const gapMobile = 4;
export const Item = s.div`
  width: calc(33.333% - ${gapDesktop * 2}px);
  padding-bottom: calc(33.333% - 2px);
  margin: ${gapDesktop}px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  @media (max-width: ${({ theme }) => theme.mobileBp}) {
    scroll-snap-align: center;
    min-width: 90%;
    margin: ${gapMobile}px;
    padding-bottom: 100%;
  }
`;
export const Image = s.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
