import React from "react";
import PT from "prop-types";
import { Image, Link, Item, Meta, Location, AngleRight } from "./slide-styled";

const Slide = ({ city, t }) => {
  const { title, image, url } = city;
  return (
    <Item key={title}>
      <Image loading="lazy" src={image} alt="" />
      <Meta>
        <Location>{title}</Location>
        <Link to={url} aria-label={title}>
          <span>{t("home_page:places.cta")}</span>
          <AngleRight />
        </Link>
      </Meta>
    </Item>
  );
};
Slide.propTypes = {
  chunk: PT.object,
};

export default Slide;
