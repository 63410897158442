import styled from "styled-components";

export const BlogWrapp = styled.section`
  width: 100%;
  position: relative;
  z-index: 36;
  height: 655px;
  margin: 0 auto;
  padding-top: 60px;
  .blog-content {
    padding: 0;
    position: relative;
    .blog-img {
      width: 105%;
      top: 0;
      right: -30px;
    }
    div {
      position: absolute;
      top: 50%;
      width: 100%;
      text-align: center;
      color: white;
      a {
        color: white;
      }
    }
  }
  img {
    width: 100%;
  }
  @media (max-width: 991px) {
    height: auto;
    overflow: hidden;
    padding-top: 17px;
  }
`;
export const Wave = styled.section`
  width: 104px;
  height: 20px;
  margin: 0 auto;
  background: url("/images/wave.png") repeat-x;
  padding-bottom: 45px;
  @media (max-width: 991px) {
    margin-bottom: 0;
  }
`;

export const BlogItems = styled.div`
  position: relative;
  background: ${({ isVisible }) =>
    isVisible ? 'url("/img/girls2.png") no-repeat' : "none"};
  height: 100%;
  background-position: center;
  padding-top: 0;
  margin-top: -66px;
  & > div {
    position: relative;
    max-width: 1200px;
    height: 620px;
    margin: 0 auto;
  }
  @media (max-width: 991px) {
    background: ${({ isVisible }) =>
      isVisible ? 'url("/img/girls.png") no-repeat' : "none"};
    background-position: center;
    background-size: 320px;
    height: 718px;
    padding-top: 53px;
    margin-top: 0;
    & > div {
      width: 320px;
      height: 100%;
    }
  }
`;
export const Item1 = styled.div`
    position:absolute;
    position:absolute;
    width:155px;
    top: 35%;
    left: 10%;
    color:#fff;
    font-weight: 500;
    font-size:22px;
    line-height: 28px;
      a {
        color:#fff;
        font-size:14px;
      }
      p {
        margin:0;
      }
    }
    @media(max-width: 1200px) {
      left: 6vw;
    }
    @media (max-width: 991px) {
      width:128px;
      position:relative;
      font-size:18px;
      left: 20px;
      top: 0;
    }
`;

export const Item2 = styled.div`
  position:absolute;
  width:171px;
  top: 35%;
  left: 30%;
  color:#fff;
  font-weight: 500;
  font-size:22px;
  line-height: 28px;
    a {
      color:#fff;
      font-size:14px;
    }
    p {
      margin:0;
    }
  }
  @media (max-width: 991px) {
    width:171px;
    position:relative;
    font-size:18px;
    left: 20px;
    top:194px;
  }
    
`;

export const Item3 = styled.div`
    position:absolute;
    width:400px;
    top: 37%;
    right: 5%;
    color:#fff;
    font-size:65px;
    line-height: 50px;
      a {
        font-weight: 700;
        color:#fff;
        font-size:14px;
      }
      p {
        font-weight: 300;
        margin:0;
      }
    }
    @media(max-width: 1200px) {
      right: 1vw;
    }
    @media (max-width: 991px) {
    width:171px;
    line-height: 1.4;
    position:relative;
    font-size:24px;
    left: 20px;
    top: 321px;
`;
